import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import './style.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from 'axios'
import VueAxios from 'vue-axios'

const app = createApp(App);
 
app.use(VueAxios, axios)
app.use(VueSweetalert2)
app.use(router);

const url = 'https://expressando-backend.azurewebsites.net/api'
//const url = 'http://localhost:3000/api'  
axios.defaults.baseURL = url

app.mount('#app')
