<template>
  <div class="">
    <!-- imagen -->
    <!-- AFGG Commented out 20240718 <div class="relative custom-height full-width">-->
      <div class="relative h-auto mx-auto lg:max-w-2xl">
      <img class="logo z-30 absolute mt-0 full-height" src="../src/assets/logoexpressando-04.png">
      <img class="z-0 full-height" src="../src/assets/publicidad-expressando.jpg">
      <!-- AFGG Commented out 20240718
      <img class="absolute full-size " src="../src/assets/BannerWebReal.png">
      -->
    </div>

    <!-- texto -->
    <!-- AFGG Commented out 20240718
    <div class="">
      <p class="lg:px-60 font-normal text-md ">
        Este espacio está hecho para ti, en él podrás expresarte libremente de manera escrita y dejar todo lo que
        necesites comunicar. Además es anónimo 😉
      </p>
    </div>
    -->
    <div class="inline-grid grid-cols-1 md:grid-cols-1 gap-2 mt-5 ">
      <!-- AFGG Commented out 20240718
      <buttton @click.prevent="modalNuevoMsn = true"
        class="cursor-pointer text-lg  font-bold hover:bg-black hover:text-pink-400 focus:ring-4 focus:ring-gray-800  rounded-lg px-5 py-2.5 lg:w-60 mx-auto"
        type="button">¡Expréssate!</buttton>-->
      <buttton @click.prevent="modalNuevoMsn = true"
        class="cursor-pointer text-lg  font-bold bg-black text-pink-400 focus:ring-4 focus:ring-gray-800  rounded-lg px-5 py-2.5 lg:w-80 mx-auto"
        type="button">¡Expréssate!</buttton>
      <buttton @click.prevent="modalInfo = true"
        class="cursor-pointer text-lg  font-bold hover:bg-black hover:text-pink-400 focus:ring-4 focus:ring-gray-800  rounded-lg px-5 py-2.5 lg:w-20 mx-auto"
        type="button">¿?</buttton>
    </div>
    <!-- modal crear mensaje -->
    <div v-show="modalNuevoMsn" id="default-modal" tabindex="-1" aria-hidden="true"
      class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div class="relative p-4 w-full max-w-2xl max-h-full mx-auto">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow border-gray-600">
          <!-- Modal header -->
          <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t bg-black border-gray-600">
            <h3 class="text-xl font-semibold text-pink-600 ">
              Nueva Expressión
            </h3>
            <button @click.prevent="modalNuevoMsn = false" type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="default-modal">
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-4 md:p-5 space-y-4">
            <div class="">
              <h4 v-show="mensajeinicial" class="p-4 text-base leading-relaxed text-gray-500 dark:text-gray-400">Expressar
                libera, siéntete libre de escribir todo lo que quieras soltar.
                Además es anónimo 😉</h4>
              <button type="button" @click.prevent="cambioEstado()" v-show="mensajeinicial"
                class="cursor-pointer ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                Ok
              </button>
            </div>
            <!-- preguntas orientadoras -->
            <div class="wrapper-card grid lg:grid-cols-2 grid-cols-1 md:grid-cols-2 gap-2 mt-5" v-show="!PreguntaOr">
              <div
                class="block lg:w-44 md:w-60 w-80 py-6 border border-pink-500 rounded-lg shadow hover:bg-gray-300 mx-auto"
                type="button">
                <button type="button" @click.prevent="tomadatos()">
                  <!-- class="block max-w-sm p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mx-auto" type="button"> -->
                  <p class="font-normal p-2"><b>Quiero escribir algo libre</b></p>
                </button>
              </div>
              <div v-for="(item, index) in preguntasOrientadoras" :key="index" :value="item.Id"
                class="block lg:w-44 md:w-60 w-80 py-6 border border-pink-500 rounded-lg shadow hover:bg-gray-300 mx-auto"
                type="button">
                <button type="button" @click.prevent="tomadatospregunta(item)">
                  <!-- class="block max-w-sm p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mx-auto" type="button"> -->
                  <p class="font-normal p-2 ">{{ item.Pregunta }}</p>
                </button>
              </div>
            </div>
            <form class="max-w-sm mx-auto" v-show="mostrar">
              <label class="block mb-2 text-xl font-medium text-gray-900 ">{{ textPreguntaOr }}</label>

              <div class="">
                <label class="block mb-2 text-sm font-medium text-gray-900 ">Elige un tipo de letra:</label>
                <div class="wrapper-card grid lg:grid-cols-2 grid-cols-1 md:grid-cols-2 gap-2 mt-5 ">

                  <div
                    class=" block lg:w-44 md:w-60 w-80 py-6 border border-gray-200 rounded-lg shadow hover:bg-gray-300 mx-auto focus:ring-gray-500"
                    type="button" v-for="(item, index) in listafuentes" :key="index"
                    :class="{ 'bg-gray-300': item.estado }">
                    <button type="button  p-6 " :style="{ 'font-family': item.fuente }"
                      @click.prevent="CapturaFuente(item, index)">{{ item.txtfuente }}
                      <p class="font-normal  "></p>
                    </button>
                  </div>

                </div>
              </div>

              <div class="row my-3">
                <label for="" class="block mb-2 text-sm font-medium text-gray-900 ">Color para la tarjeta:</label>
                <div class="wrapper-card grid lg:grid-cols-5 grid-cols-2 md:grid-cols-3 gap-2 mt-5">
                  <div class="block max-w-md  p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-300 mx-auto"
                    type="button" style="background-color: #FE0202">
                    <button type="button" @click.prevent="CapturaColor('#FE0202')">
                      <!-- class="block max-w-sm p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mx-auto" type="button"> -->
                      <p class="font-normal " style="color: #FE0202"> col</p>
                    </button>
                  </div>
                  <div class="block max-w-md  p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-300 mx-auto"
                    type="button" style="background-color: #7ccad6">
                    <button type="button" @click.prevent="CapturaColor('#7ccad6')">
                      <!-- class="block max-w-sm p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mx-auto" type="button"> -->
                      <p class="font-normal " style="color: #7ccad6"> col</p>
                    </button>
                  </div>
                  <div class="block max-w-md  p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-300 mx-auto"
                    type="button" style="background-color: #448677">
                    <button type="button" @click.prevent="CapturaColor('#448677')">
                      <!-- class="block max-w-sm p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mx-auto" type="button"> -->
                      <p class="font-normal " style="color: #448677"> col</p>
                    </button>
                  </div>
                  <div class="block max-w-md  p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-300 mx-auto"
                    type="button" style="background-color: #353535">
                    <button type="button" @click.prevent="CapturaColor('#353535')">
                      <!-- class="block max-w-sm p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mx-auto" type="button"> -->
                      <p class="font-normal " style="color: #353535"> col</p>
                    </button>
                  </div>
                  <div class="block max-w-md  p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-300 mx-auto"
                    type="button" style="background-color: #bc83e6">
                    <button type="button" @click.prevent="CapturaColor('#bc83e6')">
                      <!-- class="block max-w-sm p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mx-auto" type="button"> -->
                      <p class="font-normal " style="color: #bc83e6"> col</p>
                    </button>
                  </div>
                  <div class="block max-w-md  p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-300 mx-auto"
                    type="button" style="background-color: #5eb1e4">
                    <button type="button" @click.prevent="CapturaColor('#5eb1e4')">
                      <!-- class="block max-w-sm p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mx-auto" type="button"> -->
                      <p class="font-normal " style="color: #5eb1e4"> col</p>
                    </button>
                  </div>
                  <div class="block max-w-md  p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-300 mx-auto"
                    type="button" style="background-color: #FE5702">
                    <button type="button" @click.prevent="CapturaColor('#FE5702')">
                      <!-- class="block max-w-sm p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mx-auto" type="button"> -->
                      <p class="font-normal " style="color: #FE5702"> col</p>
                    </button>
                  </div>
                  <div class="block max-w-md  p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-300 mx-auto"
                    type="button" style="background-color: #ECFE02">
                    <button type="button" @click.prevent="CapturaColor('#ECFE02')">
                      <!-- class="block max-w-sm p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mx-auto" type="button"> -->
                      <p class="font-normal " style="color: #ECFE02"> col</p>
                    </button>
                  </div>
                  <div class="block max-w-md  p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-300 mx-auto"
                    type="button" style="background-color: #69412c">
                    <button type="button" @click.prevent="CapturaColor('#69412c')">
                      <!-- class="block max-w-sm p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mx-auto" type="button"> -->
                      <p class="font-normal " style="color: #69412c"> col</p>
                    </button>
                  </div>
                </div>
              </div>
              <div class="inline-grid grid-cols-2">

                <label for="message-text" class="block mb-2 text-sm font-medium text-gray-900 ">Mensaje:</label>
                <button @click.prevent="ActivarEmojis = !ActivarEmojis" class="btn">
                  <a title="Para usar emojis pulsa aquí .">👋</a>
                </button>

              </div>
              <div class=" grid-cols-1 gap-2 mt-5  ">
                <div class="">
                  <textarea maxlength="4000" :class="{ 'text-white': letra }" id="message-text"
                    class=" block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                    v-model="msn.Texto" :style="{ background: msn.Color, 'font-family': msn.Fuente }" required cols="30"
                    rows="8"></textarea>
                </div>
                <EmojiPicker :native="true" @select="onSelectEmoji" v-show="ActivarEmojis" />
              </div>
              <div class="grid-cols-1 gap-2 mt-5 mx-auto">
                <button @click.prevent="elNavegadorEsCompatible()"
                  class="cursor-pointer ms-3 text-pink-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-pink-400 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  type="button"> Usa el micrófono
                  <div role="status" v-show="iniciarmicro">
                    <svg aria-hidden="true"
                      class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-pink-600"
                      viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor" />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill" />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </form>
          </div>
          <!-- Modal footer -->
          <div
            class="cursor-pointer flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button @click.prevent="modalNuevoMsn = false" type="button"
              class="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cerrar</button>
            <button type="button" v-show="mostrar"
              class="cursor-pointer ms-3 text-pink-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-pink-400 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              @click.prevent="limpiarMensaje()">
              Limpiar mensaje
            </button>
            <button type="button" v-show="mostrar"
              class="cursor-pointer ms-3 text-pink-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-pink-400 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              @click.prevent="crearMensaje(msn)">
              ¡Expréssalo!
            </button>
          </div>
        </div>
      </div>
    </div>


    <!-- tarjetas -->
    <div class="wrapper-card grid lg:grid-cols-3 grid-cols-1 md:grid-cols-2 gap-2 mt-5">
      <div v-for="item in notas" :key="item.id"
        class="block w-full  p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-300 mx-auto" type="button"
        :style="{ backgroundColor: item.Color, 'font-family': item.Fuente, 'color': item.colorLetra }">
        <h5 class="mb-2 text-2xl font-bold tracking-tight ">{{ item.Texto }}</h5>
        <button type="button" class="">
          <!-- class="block max-w-sm p-6 border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mx-auto" type="button"> -->
          <p class="font-normal  ">{{ item.fechafinal }}</p>
        </button>
      </div>
    </div>
    <!-- boton ver más -->
    <div class=" my-4">
      <buttton @click.prevent="vermas()"
        class="cursor-pointer text-lg  font-bold hover:bg-black hover:text-pink-400 focus:ring-4 focus:ring-gray-800  rounded-lg px-5 py-2.5 lg:w-60 mx-auto"
        type="button">Ver más</buttton>
    </div>
    <!-- boton flotante -->
    <div class="flotante">
      <a href=" https://wa.me/573122812428" target="_blank" rel="noopener noreferrer" class="btn-flotante">
        <i class="bi bi-whatsapp"></i>
        <label for="" class="ml-1"> Quiero hablar con alguien.</label>
      </a>
    </div>
    <!-- modal ver mensaje-->
    <div v-show="modalInfo" id="default-modal" tabindex="-1" aria-hidden="true"
      class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div class="relative p-4 w-full max-w-2xl max-h-full mx-auto">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow border-gray-600">
          <!-- Modal header -->
          <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t bg-black border-gray-600">
            <h3 class="text-xl font-semibold text-pink-600 ">
              Información
            </h3>
            <button @click.prevent="modalInfo = false" type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="default-modal">
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
              </svg>

            </button>
          </div>
          <!-- Modal body -->
          <div class="p-4 md:p-5 space-y-4">
            <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <!-- AFGG commented out 20240718
              <strong style="font-family: 'HARLOWSI';"> ExpressAndo</strong> -->
              <strong>ExpressAndo </strong> 
              es una iniciativa innovadora que resalta la importancia de la expresión terapéutica.
              Es fundamental en el proceso de autoconocimiento y autocuidado, pues busca liberar nuestro cerebro y nuestro cuerpo 
              de una carga emocional que puede resultar perjudicial.
            </p>
            <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Expressar el estado de ánimo de manera escrita ayuda a dar equilibrio a nuestro lenguaje interno organizando las ideas y pensamientos intrusivos, 
              nos ayuda a reconocer cómo nos sentimos y ser conscientes de ello. Nos ayuda a reducir los niveles de ansiedad y, por ende, a mejorar nuestro estado de ánimo.
            </p>
            <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              Este espacio lo hemos creado con la intención de que puedas Expressar libremente y de forma anónima, queremos 
              que sea tu espacio seguro para descargarte de un mal día o compartir esa emoción que te desborda hoy.
            </p>
            <p>
              <b>
              <a href="https://www.instagram.com/expressando.ando?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" class="cursor-pointer text-lg  font-bold hover:bg-black hover:text-pink-400 focus:ring-4 focus:ring-gray-800  rounded-lg px-5 py-2.5 lg:w-60 mx-auto">Síguenos en Instagram</a>
              </b>
            </p>
          </div>
          <!-- Modal footer -->
          <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button @click.prevent="modalInfo = false" type="button"
              class="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- grid wrapper card -->
</template>

<script>
import moment from "moment";
// import picker compopnent
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css'

const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition)();
export default {
  components: {
    EmojiPicker
  },
  data() {
    return {
      ActivarEmojis: false,
      PreguntaOr: true,
      textPreguntaOr: '',
      mostrar: false,
      iniciarmicro: false,
      notas: [],
      Fecha: "",
      letra: false,
      note: {
        Color: "",
        Texto: "",
        Fecha: "",
        Fuente: "",
        Ip: ""

      },
      msn: {
        Color: "",
        Texto: "",
        Fecha: "",
        Fuente: "",
        Ip: "",
        IdPreguntaOrientadora: 0
      },
      textoMicro: '',
      modalInfo: false,
      modalNuevoMsn: false,
      mensajeinicial: true,
      limit: 30,
      preguntasOrientadoras: [],
      activo: '',
      listafuentes: [
        {
          fuente: 'serif',
          txtfuente: 'Times New Roman',
          estado: false
        },
        {
          fuente: 'Arial',
          txtfuente: 'Arial, Helvetica',
          estado: false
        },
        {
          fuente: 'Architects Daughter',
          txtfuente: 'Architects Daughter',
          estado: false
        },
        {
          fuente: 'cursive',
          txtfuente: 'Comic Sans    ',
          estado: false
        },
        {
          fuente: 'monospace',
          txtfuente: 'Courier New,  Consolas',
          estado: false
        },
        {
          fuente: 'HARLOWSI',
          txtfuente: 'Harlow, italic   ',
          estado: false
        },
      ],
      txt: ''

    };
  },
  mounted() {
    this.listarNotas();
    this.getIpClient();
    this.preguntasorientadoras()
  },
  methods: {
    onSelectEmoji(emoji) {
      console.log(emoji)
      const textomensaje = this.msn.Texto + emoji.i
      this.msn.Texto = textomensaje
    },
    async getIpClient() {
      try {
        const response = await this.axios.get(
          "https://api.ipify.org?format=json"
        );
        //console.log(response.data.ip);
        this.msn.Ip = response.data.ip;
        //console.log(this.msn.Ip);
      } catch (error) {
        console.error(error);
      }
    },
    listarNotas() {
      this.axios
        .get(`/nota/${this.limit}`)
        .then((response) => {
          this.notas = response.data;
          //console.log(response.data)
          for (let index = 0; index < this.notas.length; index++) {
            this.notas[index].fechafinal = moment(this.notas[index].Fecha).format("DD/MM/YYYY")

            if (this.notas[index].Color == "#353535" || this.notas[index].Color == "#432312") {
              this.notas[index].colorLetra = "#FFFFFF"
            }
          }
        })
        .catch((e) => {
          console.log("error" + e);
        });
    },
    mostrarMensaje(id) {
      console.log(id);
      this.axios
        .get(`/nota/${id}`)
        .then((res) => {
          /* console.log(id);
        console.log(this.notas); */
          /* console.log(res.data);
          console.log(res.data[0].Color); */
          this.note.Fecha = moment(res.data[0].Fecha).format("DD/MM/YYYY");
          this.note.Texto = res.data[0].Texto;
          this.note.Color = res.data[0].Color;
          this.note.Fuente = res.data[0].Fuente;
          this.note.colorLetra = res.data[0].colorLetra
        })
        .catch((e) => {
          console.log("error" + e);
        });
    },
    crearMensaje() {
      //console.log(this.msn.Texto)
      if (this.msn.Texto == "") {
        this.$swal({
          icon: 'error',
          title: 'Ocurrió un error',
          text: 'El campo para escribir el mensaje no puede ir vacio',
        });

      } else {
        this.axios
          .post("/nota", this.msn)
          .then((res) => {
            this.notas.push(res.data);
            //console.log(res.data);
            location.reload()
            this.listarNotas();
            this.msn.Color = "";
            this.msn.Fuente = "";
            this.msn.Texto = "";
            this.mensajeinicial = false
            this.PreguntaOr = false
            this.mostrar = false
          })
          .catch((e) => {
            console.log("error" + e);
          });

      }
    },
    vermas() {
      this.limit = this.limit + 30
      console.log(this.limit)
      this.listarNotas()
    },
    preguntasorientadoras() {
      this.axios
        .get(`/pregunta-orientadora`)
        .then((res) => {

          for (let index = 0; index < res.data.dato.length; index++) {
            const element = res.data.dato;
            const aleatorio = element[Math.floor(Math.random() * element.length)]

            if (this.preguntasOrientadoras.length <= 2) {
              if (!this.preguntasOrientadoras.includes(aleatorio)) {
                this.preguntasOrientadoras.push(aleatorio)
              }
            }
          }

        })
        .catch((e) => {
          console.log("error" + e);
        });
    },
    cambioEstado() {
      this.mensajeinicial = false
      this.PreguntaOr = false
    },
    tomadatospregunta(data) {
      console.log(data)
      this.textPreguntaOr = data.Pregunta
      this.msn.IdPreguntaOrientadora = data.Id
      this.PreguntaOr = true
      this.mensajeinicial = false
      this.mostrar = true
    },
    tomadatos() {
      this.textPreguntaOr = 'Quiero escribir algo libre'
      this.msn.IdPreguntaOrientadora = 0
      this.PreguntaOr = true
      this.mensajeinicial = false
      this.mostrar = true
    },
    CapturaFuente(datosF, index) {
      console.log(datosF.fuente, index)
      this.msn.Fuente = datosF.fuente

      for (let index = 0; index < this.listafuentes.length; index++) {
        this.listafuentes[index].estado = false;
      }

      this.listafuentes[index].estado = true


    },
    CapturaColor(color) {
      console.log(color)
      this.msn.Color = color
    },
    elNavegadorEsCompatible() {
      if (navigator.userAgent.indexOf("Chrome") || navigator.userAgent.indexOf("Edge") || navigator.userAgent.indexOf("Safari")) {
        //alert('El navegador es compatible')
        this.iniciarmicro = true
        recognition.lang = "es-US";
        recognition.start();
        recognition.onend = event => { this.iniciarmicro = false };
        recognition.onresult = resultado => {
          console.log(resultado.results[resultado.results.length - 1][0].transcript)
          this.txt = resultado.results[resultado.results.length - 1][0].transcript
          const textomensaje = this.msn.Texto + ' ' + this.txt
          this.msn.Texto = textomensaje
        };

      }
    },
    limpiarMensaje() {
      this.msn.Texto = ''
    }
  },
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap");

.logo:hover {
  filter: drop-shadow(0 0 4em #F17EB8);
}

.bi-whatsapp {
  font-size: 28px;
  /* Cambiar el tamaño de la tipografia */
}


/* Boton flotante */
.btn-flotante {
  color: #ffffff;
  border-radius: 25px 0 0 25px;
  background-color: Black;
  padding: 7px;
  position: fixed;
  bottom: 45px;
  right: 0;
  width: 130px;
  transition: all 300ms ease 0ms;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  z-index: 45;
  font-size: 10px;
}

.btn-flotante:hover {
  background-color: transparent;
  border: 2px solid #00BB2D;
  color: #00BB2D;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-7px);
}
</style>
